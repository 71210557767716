<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg fill="#fff" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z">
            </path>
          </svg>
          <span> Go Back</span>
        </a>

        <div class="title_name w-100 text-center" style="margin-top:0px;margin-left: 5%;">
          <h6 class="mb-0 pt-1">Aviator</h6>
        </div>

        <div class="title_name w-100 text-center" style="margin-top:0px">
          <h6 style="float: right;margin-right: 10px;" class="mb-0 p-2">
            <a class="header-join-button small-text" style="color: #000 !important;margin-top: 9px;" data-toggle="modal"
              data-target="#accountTopUp">
              Deposit
            </a>
          </h6>
        </div>

        <div class="modal fade" id="accountTopUp" tabindex="-1" role="dialog" aria-labelledby="accountTopUpLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog" role="document">
            <div style="background-color: white;color: black;" class="modal-content">

              <div class="modal-header">
                <h5 class="modal-title text-light" id="exampleModalLabel">Deposit</h5>
                <span type="button" class="close" data-dismiss="modal">&times;</span>
              </div>
              <div class="modal-body">
                <section class="px-2 mb-3">
                  <div class="form-wrapper1 s-a-1 mb-0">

                    <div class="mb-0">
                      <input
                        style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
                        type="number" name="amount" id="deposit" placeholder="10" min="0" class="form-control"
                        value="99" aria-label="Amount (to the nearest dollar)" v-model="amount" />
                    </div>
                    <br />
                    <div class="d-flex justify-content-between mb-2">
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);"
                          class="btn odd-btn px-2" @click="setAmount(20)">
                          +<span class="deposit_val">20</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);"
                          class="btn odd-btn px-2" @click="setAmount(50)">
                          +<span class="deposit_val">50</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);"
                          class="btn odd-btn px-2" @click="setAmount(100)">
                          +<span class="deposit_val">100</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button style="background-color: var(--darkBackground);color: var(--white);"
                          class="btn odd-btn px-2" @click="setAmount(500)">
                          +<span class="deposit_val">500</span>
                        </button>
                      </div>
                    </div>
                    <small style="color: var(--darkBackground);" class="s-a-1-txt mt-3 mb-3">Minimum Deposit amount: Ksh
                      10</small>
                    <button style="background-color: var(--darkBackground);color: var(--white);" @click="deposit"
                      id="deposit-profile" v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
                      Deposit
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen
        webkitallowfullscreen></iframe>
      <div class="banner d-flex">
        <p class="btn btn-link btn-sm" style="color: #ffffff">
          <span> Aviator Page</span>
        </p>
      </div>
      <div style="display:none;">
        <h1>Aviator Kenya — An Ideal Multiplayer Adventure</h1>
        <p>Ever since its debut in February 2019, the Aviator casino game has captured global interest, and its fan base
          has been on a consistent rise. A growing number of iGamers are playing the online version of Aviator,
          developed by one of the top tier developers, Spribe. Numerous frontline online casinos, including qwerty,
          have the Aviator game in their gaming lounge.</p>

        <p>The Aviator game qwerty by Spribe offers a delightful, swift, straightforward, and adventurous gambling
          experience, following a concept similar to popular casino games such as JetX, Rocketman, Cometcrash, and
          FootballX among others with similar themes. Now, let's have a look into what makes the Aviator game special,
          how to download it, and gather useful insights about Aviator Spribe to enhance your enjoyment of this
          thrilling online gaming experience!</p>

        <h2>Features of Aviator Game by Spribe</h2>
        <p>The straightforward gameplay of Aviator attracts millions of Kenyan players to engage in this online betting
          game. As you can guess, Aviator is one of the most popular ‘fastest finger-first’ online casino games with an
          aviation theme.</p>

        <p>The Aviator Kenya game interface makes you sit in a plane's cockpit, featuring an altimeter displaying the
          altitude as the aircraft accelerates for takeoff. As the altitude increases, its multiplier coefficient also
          increases.</p>

        <p>The qwerty Aviator app offers multipliers ranging from 1 to 1 million times your initial bet. You need
          to place your bet within a 5-second window to engage in the game and potentially win big. After that, you only
          need to observe the plane's altitude as the corresponding multiplier keeps increasing.</p>

        <p>In the Aviator game, bets result in a multiplier linked to the plane's altitude. It's essential to cash out
          before the plane vanishes to secure your winnings. If the plane takes off before withdrawing, you lose your
          game Aviator bet.</p>

        <p>The Aviator slot maintains a 43% hit rate and a medium level of volatility. Additionally, this online casino
          game offers an impressive RTP of 97%.</p>

        <p>Players using the Aviator betting game app can wager between Ksh.10 (min) and Ks.10,000 (max) in a single
          round.</p>

        <h3>Interface of the Aviator Game</h3>
        <p>Aviator Kenya from Spribe is a richly animated online game which also has fantastic background music. It
          features a three-fold user interface, enhancing the multi-player gaming experience. The top section of the
          screen offers access to game tutorials, settings, and a historical data where you can have a look at the
          results (multipliers) of the concluded rounds. This makes qwerty Aviator prediction a bit easy.
          Additionally, on the right-hand side of your screen, there is a live chat feature where you can interact with
          other players.</p>

        <p>The second section of the interface is split into two parts, which allows you to place two bets
          simultaneously. Here, you can start enjoying the excitement of the game by initiating an Aviator bet and
          closely tracking the aircraft's ascent, leading to multiplier gains.</p>

        <p>The third section is on the left side of the game screen, which allows you to view the stats of other
          competing players. Here, you can access details about your own wins and those of fellow players as well. This
          section also displays information on withdrawals made and the highest stakes won by the players in the
          previous rounds.</p>

        <h4>Features of qwerty Aviator Main Screen</h4>
        <p>The middle part of the screen in Aviator live game is the focal point of the action, where you set your
          Aviator game bet.</p>

        <p>Here, you determine the desired stake multiplier keeping in mind the qwerty Aviator predictor before the
          plane takes off and hope that it reaches a significant altitude so you can win big.</p>

        <h5>Features of Aviator Online Game Betting Control Board</h5>
        <p>The bottom section of the Aviator game’s interface features an 'Auto Betting' option. It allows you to place
          automated bets for the next game round based on your previous betting amount. Additionally, you can track your
          winnings in this section of the qwerty Aviator interface and opt for an auto-cashout if you want to.</p>

        <h6>Aviator qwerty Bet Board</h6>
        <p>Once you start playing the Aviator gambling game, you'll realize that you're not the only one enjoying this
          roller coaster ride. On the left side of your screen, you can see the avatars of various players from across
          the globe. Here, you can also track the most significant qwerty Aviator bet wins and stay updated on the
          latest betting and withdrawal trends.</p>

        <h2>Aviator Game Kenya General Rules</h2>
        <p>The Aviator betting game is known for its simplicity and straightforward rules. Each game round is swift,
          with a quick 5-second preparation period. In the Aviator qwerty game, you can place at least two bets
          within this brief window before the game round begins. Predicting various events within the game is possible,
          but accuracy is vital for a winning bet.</p>

        <p>The main goal of the qwerty app Aviator is that you place a wager and land on a random multiplier that
          multiplies your betting amount, starting at 1x, based on your plane’s altitude, before it takes off. To secure
          your winnings, withdraw immediately; otherwise, you lose your bet if the aircraft takes off before cashing
          out. The game's results are random and operate according to Provable Fair Technology rules. All you have to do
          is place your bet and anticipate a favourable multiplier by looking at the game’s previous multipliers. For
          instance, if you bet Ksh.1,000 and get a multiplier of 2.25x your stake, your total winnings would be
          Ksh.2,250. However, please remember that you must cashout before the plane takes off to claim your winnings.
        </p>

        <h2>Advantages of Playing Kenya qwerty Aviator Game</h2>
        <p>The live online version of Spribe Aviator at qwerty has numerous advantages, cementing its position as
          one of the most desirable aviator games globally. Below are the key benefits of experiencing this thrilling
          game:</p>

        <p>Real-time entertainment: One of the primary attractions of the live Aviator Kenya game is that it offers an
          authentic real-time gaming experience. It's akin to being at the helm of an exhilarating adventure, enabling
          you to take charge of the gameplay and pursue exciting rewards.</p>

        <p>Comfort: Comfort is a significant perk for enjoying the qwerty Aviator Live Online Game. It offers the
          flexibility of playing from anywhere convenient to you. You do not need to go to a brick-and-mortar casino to
          experience its excitement physically. You only need a reliable internet connection and a compatible device
          installed with the qwerty Aviator game app to embark on an adventurous flight that keeps the adrenaline
          pumping.</p>

        <p>Privacy and Security: Regarding the Aviator game online at qwerty, privacy and security are top
          priorities. The platform employs advanced encryption technology to safeguard your personal data and ensure
          your funds' security. With qwerty Aviator login, you can have peace of mind knowing that your personal
          and financial information is well-protected, especially considering Aviator game's legal status in Kenya.</p>

        <p>Ease of Access: Aviator qwerty login is known for its user-friendly interface. There's no need to go
          through the hassle of downloading the qwerty Aviator game from our website. Simply head to our homepage
          or casino tab and start playing Aviator online right away. At qwerty, we always strive to make gaming
          effortless and easily accessible.</p>

        <p>Diverse Selection: At qwerty, the Aviator game online presents various gaming options, including JetX,
          Cometcrash, Aviatrix, FootballX, Baccarat, slots, roulette, and blackjack. This ensures your gaming experience
          is dynamic and enjoyable every time you log in to our website.</p>

        <p>Bonus Offers and Promotions: qwerty gives away a variety of bonus offers and promotions aimed at
          boosting your winnings and enhancing your overall gaming journey.</p>

        <p>Customer Support: Our dedicated team at qwerty is available around the clock on Customer Support Phone
          Number 0716797777 to assist you with any inquiries or concerns regarding Aviator Casino Kenya. Rest assured,
          your questions will be addressed promptly and effectively.</p>

        <p>These highlights represent just a few perks you can enjoy when playing the Aviator game online at
          qwerty. With so many advantages at your disposal, it's no surprise that this game is gaining immense
          popularity among our online gaming enthusiasts.</p>

        <h2>Guide for Aviator Live Online Gameplay</h2>
        <p>Having understood the advantages of playing the Aviator game online at qwerty, let's know how the game
          is played. Below is a comprehensive step-by-step guide on playing the live online Aviator game at qwerty:
        </p>

        <p>Step 1: Account Creation – Begin by creating an account on qwerty. Follow the on-screen instructions to
          complete your Aviator login process.</p>

        <p>Step 2: Fund Deposit – Once you have registered your account, proceed to deposit funds into your qwerty
          account. You can select M-Pesa as your payment option for hassle-free deposits. There is no minimum deposit
          requirement.</p>

        <p>Step 3: Locate the Aviator Plane Game – Navigate to our gaming lounge and find the Aviator game listed among
          our offerings. The qwerty Aviator game Kenya is easy to locate.</p>

        <p>Step 4: Access the Game – Simply click on the Aviator Kenya game, and the interface mentioned above will
          load.</p>

        <p>Step 5: Start Playing – You're all set to experience the thrill of Aviator gaming! Use the controls at the
          bottom of the interface to place your bets. Stay alert to take advantage of the real-time multiplier
          coefficient that grows exponentially as the plane climbs. Make sure to cash out before the plane flies off the
          screen to secure your win.</p>

        <h4>Strategies and Tips for Playing Aviator Game Online</h4>
        <p>It’s worth noting that the Aviator game involves both luck and strategy. You can improve your chances of
          success using effective Aviator strategies and a disciplined approach to gameplay. Here are some expert tips
          to help you master the game:</p>

        <p>1. Establish a Budget: Before starting your qwerty Aviator gameplay, establish a budget and adhere to it
          diligently. Stay within the budget you’re comfortable with, ensuring your online gaming experience remains
          enjoyable.</p>

        <p>2. Keep Track of Your Bankroll: Maintain a close watch on your bankroll, adjusting your bets accordingly. If
          you experience a losing streak, reduce your stake to mitigate losses.</p>

        <p>3. Patience is Key: The Aviator game Kenya requires patience. Instead of trying to win big in every round,
          observe the trends of previous multipliers and take calculated risks.</p>

        <p>4. Know When to Quit: Don’t let excitement lead to unwise decisions. Set specific win and loss limits, and
          stick to them. It's better to leave with a small profit than lose everything.</p>

        <p>5. Take Advantage of Offers and Promotions: Look out for bonus offers and promotions that qwerty
          provides. They can give you extra betting capital and enhance your chances of winning.</p>

        <p>6. Practice on Aviator Demo Slot: The Aviator demo slot is ideal for beginners who want to get familiar with
          the game’s mechanics before wagering real money.</p>

        <h5>Have Fun with Your Aviator Online Games at qwerty!</h5>
        <p>Engaging with the Aviator Kenya game at qwerty's online casino is a fantastic choice. The game’s unique
          mix of thrill, skill, and luck makes it one of the top online betting games in the Kenyan market today. You
          get to compete in real-time, track multipliers, and determine the ideal moment to cash out before the plane
          takes off.</p>

        <p>What are you waiting for? Start having fun with the Aviator slot and stand a chance to win real cash rewards
          at qwerty! Start your Aviator app download for Android and relish the experience today!</p>
      </div>
    </div>
  </div>
</template>

<script>

import prag from "@/services/prag";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import spribe from "@/services/spribe";
import booming from "@/services/booming";
import shacksevo from "@/services/shacksevo";

import walletserve from "@/services/walletserve";

export default {
  name: "aviator",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,

      amount: "",
    };

  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: to.params.isDemo,
        }

        this.getLaunchUrl(payload)

      },
    },
  },
  methods: {

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "aviatorlogin", params: {} });
        return;
      }

      if (this.amount < 1) {
        this.$toast.open({
          message: 'Enter amount atleast 10 KSH or above',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      // vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          // vm.setSuccess("Success", msg);
          this.$toast.open({
            message: 'Success',
            type: 'success',
            position: 'top'
          });
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              // vm.setError(
              //   "Session Expired",
              //   "Your session on this device has expired"
              // );
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.error_message,
                type: 'error',
                position: 'top'
              });
              this.$toast.open({
                message: err.response.data.error_message,
                type: 'error',
                position: 'top'
              });
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again"
            // );
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl(data) {

      console.log('Launch ' + JSON.stringify(data, undefined, 2))

      if (!this.profile) {

        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.setValue("placeBet", 3);
        this.$router.push({ name: "aviatorlogin", params: {} });
        return;
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = {};

      // Determine which endpoint and payload to use based on provider ID
      if (providerId === 1) {

        payload = {
          account_id: accountID,
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      } else if (providerId === 3) {

        payload = {
          account_id: parseInt(accountID),
          game_id: parseInt(gameId),
          game_name: gameName,
          device_type: String(device_type),
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 5) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 4) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 6) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      } else if (providerId === 7) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };


      }
      else if (providerId === 8) {

        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + '/casino',
          return_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      }
      else if (providerId === 9) {

        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + '/casino',
          lobby_url: window.location.origin + '/casino',
          providerId: providerId,
        };

      }
      else {
        console.error("Invalid providerId:", providerId);
        return; // Exit the function if providerId is invalid
      }

      console.log('provider ' + providerId + ' | payload ' + JSON.stringify(payload, undefined, 2))

      // Make API request with appropriate endpoint and payload
      return this.launchGame(payload); // Return the launch URL


    },

    launchGame: function (payload) {

      var vm = this;
      var providerId = payload.providerId;

      var service;

      switch (providerId) {
        case 1:
          service = prag;
          break;
        case 3:
          service = stp;
          break;
        case 4:
          service = elbet;
          break;
        case 5:
          service = smart;
          break;

        case 6:
          service = jetsafi;
          break;

        case 7:
          service = spribe;
          break;

        case 8:
          service = booming;
          break;

        case 9:
          service = shacksevo;
          break;

        default:
          console.error("Invalid providerId:", providerId);
          return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          }
        })
        .then(response => {

          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL)

          if (vm.launchType === "3") {

            window.open(this.launchURL);

          }

          console.log("LaunchUrl", vm.launchURL)
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;

        })
        .catch(error => {
          console.error("Error launching game:", error);
        });
    },

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>